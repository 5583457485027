$(document).ready(function (jQuery) {
    jQuery.datepicker.setDefaults({
        "closeText": "Zatvoriť",
        "currentText": "Dnes",
        "monthNames": ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"],
        "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        "nextText": "Nasledujúci",
        "prevText": "Predchádzajúci",
        "dayNames": ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"],
        "dayNamesShort": ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"],
        "dayNamesMin": ["N", "P", "U", "S", "Š", "P", "S"],
        "dateFormat": "MM d, yy",
        "firstDay": 1,
        "isRTL": false
    });
});